"use client"

import type { I18nLocale } from "@/modules/i18n/types"
import type { FunctionComponent, HTMLAttributes, PropsWithChildren } from "react"

import { clsx } from "clsx"
import { usePathname } from "next/navigation"

import { PUBLIC_LOCALES } from "@/modules/locales/constants"
import { isHome } from "@/modules/routing/helpers/isPage"

import styles from "./Header.module.css"

type InteractiveAppNavProps = PropsWithChildren<{ locale: I18nLocale }> & HTMLAttributes<HTMLElement>

export const InteractiveAppNav: FunctionComponent<InteractiveAppNavProps> = ({ children, className, locale }) => {
  const pathname = usePathname()
  const isHomepage = isHome(pathname)
  const isPublicLocale = PUBLIC_LOCALES.includes(locale)

  const withNav = isPublicLocale || (!isPublicLocale && !isHomepage)

  return (
    <div
      className={clsx(className, {
        [styles.primaryNav__hidden]: !withNav,
      })}
    >
      {children}
    </div>
  )
}
