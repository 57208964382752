"use client"

import type { FunctionComponent, HTMLAttributes, PropsWithChildren } from "react"

import { clsx } from "clsx"
import { usePathname } from "next/navigation"

import { isHome } from "@/modules/routing/helpers/isPage"

import styles from "./Header.module.css"

type InteractiveAppHeaderProps = PropsWithChildren<{
  isBannerVisible?: boolean
}> &
  HTMLAttributes<HTMLDivElement>

export const InteractiveAppHeader: FunctionComponent<InteractiveAppHeaderProps> = ({
  children,
  className,
  isBannerVisible,
}) => {
  const pathname = usePathname()
  const isHomepage = isHome(pathname)

  return (
    <header
      className={clsx(className, {
        [styles.main__fixed]: isHomepage && !isBannerVisible,
      })}
      role="banner"
    >
      {children}
    </header>
  )
}
